
export default {
  async middleware({ route, redirect }) {
    const queryString = JSON.stringify(route.fullPath)
    const substring = queryString
      .substring(queryString.indexOf("?") + 1)
      .slice(0, -1)

    return redirect(`/v2/users/connectors?${substring}`)
  },
}
